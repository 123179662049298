export const getStoryblokLinkAttributes = (link: any) => {
   const url = link?.url?.cached_url
      ? link.url.cached_url
      : link?.url?.email
      ? `mailto:${link.url.email}`
      : ''
   const title = link?.title ? link.title : ''

   return {
      url,
      title,
   }
}
