import React from 'react'

type Icon = {
  color?: string
  className?: string
  width?: number
  height?: number
  strokeWidth?: number
}

export const PlayIcon = ({
  color = 'var(--sand)',
  className,
  width = 136,
  height = 260,
}: Icon) => (
  <svg
    width={width}
    height={height}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 260"
  >
    <mask
      id="a"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="136"
      height="260"
    >
      <path fill={color} d="M0 0h136v260H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M-.912 259.702c-2.48-14.653-9.017-30.434-19.613-47.341-10.595-17.134-25.7-33.027-45.313-47.68-19.387-14.653-38.775-24.009-58.162-28.067v-14.202c19.162-4.509 37.535-12.85 55.12-25.024 17.808-12.399 32.687-27.277 44.635-44.636C-12.07 34.942-4.294 17.359-.912 0h14.203c2.028 11.272 6.086 22.882 12.173 34.83 6.087 11.723 13.864 22.994 23.333 33.815C58.49 79.241 69.31 88.822 81.259 97.388c17.81 12.625 35.957 20.966 54.443 25.024v14.202c-12.399 2.48-25.249 7.552-38.55 15.217-13.075 7.665-25.248 16.795-36.52 27.391-11.272 10.37-20.515 21.303-27.729 32.8-10.595 16.908-17.133 32.801-19.612 47.68H-.912Z"
        fill={color}
      />
    </g>
  </svg>
)
