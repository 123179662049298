import React from 'react'
// Page types
import PageHome from './Pages/PageHome/PageHome'
import PageEvent from './Pages/PageEvent/PageEvent'
import PageArtist from './Pages/PageArtist/PageArtist'
import PageContent from './Pages/PageContent/PageContent'
import PageEnglish from './Pages/PageEnglish/PageEnglish'

// Global Modules
import Header from './GlobalComponents/Header/Header'
import Footer from './GlobalComponents/Footer/Footer'

// Modules
import LineUp from './Modules/LineUp/LineUp'
import FAQ from './Modules/FAQ/FAQ'
import ArtistList from './Modules/ArtistList/ArtistList'
import LineUpPicker from './Modules/LineUpPicker/LineUpPicker'
import Tickets from './Modules/Tickets/Tickets'
import Information from './Modules/Information/Information'
import Offers from './Modules/Offers/Offers'

// Components
import ComponentNotFound from './ComponentNotFound'
import StoryblokImage from './Components/StoryblokImage/StoryblokImage'
import YoutubeVideo from './Components/YoutubeVideo/YoutubeVideo'
import StoryblokVideo from './Components/StoryblokVideo/StoryblokVideo'
import Content from './Components/Content/Content'
import GlobalSchedule from './GlobalComponents/GlobalSchedule/GlobalSchedule'
import Schedule from './Modules/Schedule/Schedule'
import Iframe from './Modules/Iframe/Iframe'
import PageTickets from './Pages/PageTickets/PageTickets'

// Modules
const ComponentList: any = {
  // Page types
  page: PageHome,
  page_event: PageEvent,
  page_artist: PageArtist,
  page_content: PageContent,
  page_english: PageEnglish,
  page_tickets: PageTickets,

  // Global Components
  header: Header,
  footer: Footer,
  schedule: GlobalSchedule,

  // Modules
  lineup: LineUp,
  faq: FAQ,
  artist_list: ArtistList,
  lineup_picker: LineUpPicker,
  tickets: Tickets,
  information: Information,
  offers: Offers,
  schedule_section: Schedule,
  iframe: Iframe,

  // Components
  image: StoryblokImage,
  youtube_video: YoutubeVideo,
  video: StoryblokVideo,
  content: Content,
}

const Components = (type: string) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
