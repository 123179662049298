import React from 'react'
import SbEditable from 'storyblok-react'

import { Box, Flex } from 'reflexbox'
import * as styles from './PageEnglish.module.scss'
import Image from 'src/components/UI/Image/Image'
import Markdown from 'src/components/UI/Markdown/Markdown'

import CTA from 'src/components/CTA/CTA'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { getStoryblokLinkAttributes } from 'src/helpers/storyblok'
import Components from 'src/storyblok/Components'
import SEOPage from 'src/components/SEOPage/SEOPage'

const PageEnglish = ({ blok, name, full_slug }: any): JSX.Element => {
  const {
    pattern,
    cta,
    first_column_info,
    second_column_info,
    third_column_info,
    sponsors,
    body,
    seo_data,
  } = blok

  return (
    <SbEditable content={blok}>
      <SEOPage
        path={full_slug}
        storyblokData={seo_data}
        customData={{
          title: name || '',
        }}
        lang="en"
      />
      {cta &&
        cta?.length > 0 &&
        cta.map((link: any) => {
          const { url = '', title = '' } = getStoryblokLinkAttributes(link)
          return url && title ? (
            <Box className={styles.cta} key={link?._uid}>
              <CTA to={url} title={title} color="var(--purple)" />
            </Box>
          ) : null
        })}

      <Flex
        paddingX={[4, null, null, 6]}
        paddingTop={['22px', null, null, 10]}
        paddingBottom={[4, null, null, 8]}
        flexDirection="column"
        className={styles.wrapper}
        style={{ minHeight: '100vh' }}
      >
        {pattern && pattern.filename && (
          <Image src={pattern.filename} className={styles.pattern} />
        )}
        <Flex flexDirection="column" className={styles.top}>
          {first_column_info && (
            <Flex
              marginBottom={4}
              className={styles.mobileInfo}
              flexDirection="column"
            >
              <Markdown>{first_column_info}</Markdown>
            </Flex>
          )}
          {second_column_info && (
            <Flex
              marginBottom={4}
              className={styles.mobileInfo}
              flexDirection="column"
            >
              <Markdown>{second_column_info}</Markdown>
            </Flex>
          )}
          {third_column_info && (
            <Flex
              marginBottom={4}
              className={styles.mobileInfo}
              flexDirection="column"
            >
              <Markdown>{third_column_info}</Markdown>
            </Flex>
          )}
        </Flex>
        <Flex
          marginBottom={8}
          width={1}
          marginTop={['auto', null, null, 0]}
          className={styles.title}
          flexDirection="column"
          as="h1"
        >
          <Box as="span">{'Rosendal'}</Box>
          <Box className={styles.second} marginLeft="auto" as="span">
            {'Garden'}
          </Box>
          <Box className={styles.third} as="span">
            {'Party'}
          </Box>
        </Flex>
        <Flex
          className={styles.footer}
          paddingX={[1, null, null, 2]}
          mt={['0', null, null, 'auto']}
        >
          {first_column_info && (
            <Flex width={1 / 8} className={styles.info} flexDirection="column">
              <Markdown>{first_column_info}</Markdown>
            </Flex>
          )}
          {second_column_info && (
            <Flex width={2 / 8} className={styles.info} flexDirection="column">
              <Markdown>{second_column_info}</Markdown>
            </Flex>
          )}
          {third_column_info && (
            <Flex className={styles.info} flexDirection="column">
              <Markdown>{third_column_info}</Markdown>
            </Flex>
          )}
          <Flex
            alignItems="flex-end"
            marginLeft="auto"
            width={[1, null, null, 'auto']}
            paddingBottom={1}
            className={styles.sponsors}
          >
            <Box marginRight={'auto'} as="span">
              {'Presented by:'}
            </Box>

            {sponsors &&
              sponsors.length > 0 &&
              sponsors.map((sponsor: any) => {
                const { image, link } = sponsor

                return image?.filename && link?.cached_url ? (
                  <LinkComponent key={image.filename} to={link?.cached_url}>
                    <img src={image.filename} />
                  </LinkComponent>
                ) : null
              })}
          </Flex>
        </Flex>
      </Flex>

      {body &&
        body?.length > 0 &&
        body.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}
    </SbEditable>
  )
}

export default PageEnglish
