import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import * as styles from './LineUpPicker.module.scss'
import SbEditable from 'storyblok-react'
import Button from 'src/components/UI/Button/Button'
import { Container } from 'src/components/UI/Grid/Grid'
import Artists from 'src/components/Artists/Artists'
import { FormattedMessage } from 'react-intl'
import Image from 'src/components/UI/Image/Image'

const LineUpPicker = ({ blok }: any) => {
  const { events, anchor_id, graphics } = blok
  const [selectedEvent, setSelectedEvent] = useState(0)
  const { lineup_roses } = graphics ? graphics : { lineup_roses: {} }

  return (
    <SbEditable content={blok}>
      <Box id={anchor_id} className={styles.wrapper}>
        {lineup_roses && lineup_roses.filename && (
          <Image
            src={lineup_roses.filename}
            className={styles.roseBottomLeft}
            style={{ top: `100vh` }}
          />
        )}
        {lineup_roses && lineup_roses.filename && (
          <Image
            src={lineup_roses.filename}
            className={styles.roseBottomRight}
            style={{ top: `100vh` }}
          />
        )}
        <Container className={styles.event} paddingY={[8, null, null, 10]}>
          <Flex height="100vh" flexDirection="column" alignItems="center">
            <Box paddingBottom={[10]} width={1} className={styles.nav}>
              <Flex
                className={styles.date}
                justifyContent="center"
                alignItems="center"
                flexDirection={['column', null, null, 'row']}
              >
                {events &&
                  events?.length > 0 &&
                  events.map((blok: any, index: number) => {
                    const { content, full_slug, uuid } = blok
                    const { date, artists } = content

                    return (
                      <Box
                        key={`lineup-menu-${uuid}`}
                        as="button"
                        paddingX={[4, null, null, 7]}
                        className={index === selectedEvent ? styles.active : ''}
                        onClick={() => setSelectedEvent(index)}
                        marginBottom={[3, null, null, 0]}
                      >
                        {artists?.length > 0 ? artists[0].name + ' – ' : ''}
                        {date}
                      </Box>
                    )
                  })}
              </Flex>
            </Box>
            {events &&
              events?.length > 0 &&
              events.map((blok: any, index: number) => {
                const { content, full_slug, uuid } = blok
                const { date, artists } = content

                return selectedEvent === index ? (
                  <Flex
                    key={`lineup-${uuid}`}
                    height="100%"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {artists && artists.length > 0 && (
                      <Flex
                        justifyContent="center"
                        marginBottom={[8, null, null, 10]}
                      >
                        <Artists artists={artists} />
                      </Flex>
                    )}

                    <Button
                      ariaLabel={`Read more about ${date}`}
                      href={full_slug}
                    >
                      <FormattedMessage id="läs-mer" defaultMessage="Läs mer" />
                    </Button>
                  </Flex>
                ) : null
              })}
          </Flex>
        </Container>
      </Box>
    </SbEditable>
  )
}

export default LineUpPicker
