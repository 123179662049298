import React from 'react'
import SbEditable from 'storyblok-react'

import { Box, Flex } from 'reflexbox'
import Image from 'src/components/UI/Image/Image'
import Markdown from 'src/components/UI/Markdown/Markdown'
import * as styles from './PageEvent.module.scss'
// import SEO from 'src/components/SEO/SEO'
import classnames from 'classnames'
import Artists from 'src/components/Artists/Artists'
import SEOPage from 'src/components/SEOPage/SEOPage'
import { Container } from 'src/components/UI/Grid/Grid'
import Components from 'src/storyblok/Components'

const PageEvent = ({ blok, full_slug }: any): JSX.Element => {
  const {
    date,
    custom_title,
    artists,
    headlines,
    medium_artists,
    image,
    image_mobile,
    body,
    seo_data,
    theme,
    graphics,
  } = blok
  const {
    graphic_1,
    graphic_2,
    graphic_3,
    graphic_4,
    graphic_5,
  }: any = graphics ? graphics : {}

  const title = `${
    headlines?.length > 0
      ? headlines[0].name + ' – '
      : artists?.length > 0
      ? artists[0].name + ' – '
      : ''
  }${date} | Rosendal Garden Party`

  let themeClass = ''
  switch (theme) {
    case 'day1':
      themeClass = styles.day1
      break
    case 'day2':
      themeClass = styles.day2
      break
    case 'day3':
      themeClass = styles.day3
      break
    default:
      break
  }

  return (
    <SbEditable content={blok}>
      <SEOPage
        path={full_slug}
        storyblokData={seo_data}
        customData={{
          title: title || '',
          image: image?.filename || '',
        }}
      />
      <Box className={styles.hero}>
        <Container className={styles.heroInner}>
          <Flex justifyContent={'center'} className={styles.top} width={1}>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems={['center', null, null, 'center']}
              height="100%"
              textAlign="center"
              paddingTop={[0, null, null, '10px']}
            >
              {custom_title ? (
                <Markdown>{custom_title}</Markdown>
              ) : (
                <React.Fragment>
                  <Box>{date}, Djurgården</Box>
                  <Box>Stockholm</Box>
                </React.Fragment>
              )}
            </Flex>
          </Flex>
          <Flex
            paddingY={[4, null, null, 7]}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            className={styles.content}
          >
            {/* {cta &&
            cta?.length > 0 &&
            cta.map((link: any) => {
              const { url = '', title = '' } = getLinkAttributes(link)
              return url && title ? (
                <Box className={styles.cta} key={link?._uid}>
                  <CTA
                    to={url}
                    title={title}
                    color="var(--purple)"
                    type="small"
                  />
                </Box>
              ) : null
            })} */}

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Artists
                artists={artists}
                headlines={headlines}
                medium_artists={medium_artists}
                className={styles.artists}
                sand
              />
              {/* <img className={styles.bee} src="/images/temporary/bee.png" />
              <img
                className={styles.fjaril}
                src="/images/temporary/fjaril.png"
              /> */}
            </Flex>
          </Flex>
          <Box className={[styles.background].join(' ')}>
            {graphic_2 && (
              <Image
                src={graphic_2.filename}
                alt={graphic_2.title}
                className={[styles.leftBottomOrange, styles.pattern].join(' ')}
              />
            )}
            {/* <img
            src="/images/temporary/2.png"
            className={[styles.leftBottomOrange, styles.pattern].join(' ')}
          /> */}
            {graphic_3 && (
              <Image
                src={graphic_3.filename}
                alt={graphic_3.title}
                className={[styles.leftBottomRed, styles.pattern].join(' ')}
              />
            )}
            {/* <img
            src="/images/temporary/6.png"
            className={[styles.leftBottomRed, styles.pattern].join(' ')}
          /> */}
            {graphic_1 && (
              <Image
                src={graphic_1.filename}
                alt={graphic_1.title}
                className={[styles.rightTopPink, styles.pattern].join(' ')}
              />
            )}
            {/* <img
            src="/images/temporary/1.png"
            className={[styles.rightTopPink, styles.pattern].join(' ')}
          /> */}
            {graphic_2 && (
              <Image
                src={graphic_2.filename}
                alt={graphic_2.title}
                className={[styles.rightTopOrange, styles.pattern].join(' ')}
              />
            )}
            {/* <img
            src="/images/temporary/2.png"
            className={[styles.rightTopOrange, styles.pattern].join(' ')}
          /> */}
            {graphic_5 && (
              <Image
                src={graphic_5.filename}
                alt={graphic_5.title}
                className={[styles.rightBottom, styles.pattern].join(' ')}
              />
            )}
            {/* <img
            src="/images/temporary/7.png"
            className={[styles.rightBottom, styles.pattern].join(' ')}
          /> */}
            {/* <img
            src="/images/temporary/8.png"
            className={[styles.mobileTopLeft, styles.pattern].join(' ')}
          /> */}
          </Box>
          <Box className={[styles.imageBackground, themeClass].join(' ')}>
            {image && image.filename ? (
              <Image
                src={image.filename}
                className={classnames(
                  styles.headline,
                  image_mobile?.filename ? 'hideMobile' : ''
                )}
              />
            ) : null}
            {image_mobile && image_mobile.filename ? (
              <Image
                src={image_mobile.filename}
                className={classnames(styles.headline, 'hideDesktop')}
              />
            ) : null}
          </Box>
        </Container>
      </Box>

      {body &&
        body?.length > 0 &&
        body.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            image_theme: theme,
          })
        )}
    </SbEditable>
  )
}

export default PageEvent
