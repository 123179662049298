// extracted by mini-css-extract-plugin
export var wrapper = "GlobalSchedule-module--wrapper--4LSHu";
export var purple = "GlobalSchedule-module--purple--3Egh8";
export var sand = "GlobalSchedule-module--sand--3ADA7";
export var schedule = "GlobalSchedule-module--schedule--2DHBS";
export var scheduleHeader = "GlobalSchedule-module--scheduleHeader--2hMx9";
export var time = "GlobalSchedule-module--time--31fhw";
export var button = "GlobalSchedule-module--button--3qdRt";
export var active = "GlobalSchedule-module--active--2NzGP";
export var roseBottomLeft = "GlobalSchedule-module--roseBottomLeft--1lq17";
export var roseBottomRight = "GlobalSchedule-module--roseBottomRight--1HzMV";
export var title = "GlobalSchedule-module--title--iXeuu";
export var description = "GlobalSchedule-module--description--2qYbz";
export var download = "GlobalSchedule-module--download--1Pqh7";
export var row = "GlobalSchedule-module--row--1FjHj";