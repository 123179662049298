import React from 'react'
import { Box, Flex } from 'reflexbox'
import ArtistCard from './ArtistCard/ArtistCard'
import * as styles from './ArtistList.module.scss'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'
const ArtistList = ({ blok, image_theme }: any) => {
  const { title, artists, anchor_id, theme } = blok

  return (
    <SbEditable content={blok}>
      <Container
        id={anchor_id}
        as="section"
        className={classnames(
          styles.wrapper,
          theme && theme === 'sand' ? styles.sand : styles.purple,
          theme && theme === 'sand' ? 'sand-section' : 'purple-section'
        )}
        paddingTop={[12, null, null, 12]}
        paddingBottom={[10, null, null, 12]}
      >
        {title && (
          <Row justifyContent="center">
            <Column width={[1, null, null, 8 / 12]}>
              <Box marginBottom={[10, null, null, 12]}>
                {title && (
                  <Box as="h2" className={styles.title}>
                    {title}
                  </Box>
                )}
              </Box>
            </Column>
          </Row>
        )}

        <Flex
          width={1}
          flexDirection="column"
          alignItems="center"
          maxWidth={['100%', null, null, 'var(--container-max-width)']}
          marginX="auto"
        >
          <Flex flexDirection="column">
            <Row className="hideMobile">
              <Column width={6 / 12}>
                {artists &&
                  artists.length > 0 &&
                  artists.map((artist: any, index: number) => {
                    const everyOther = index % 2 === 0
                    return everyOther ? (
                      <Box
                        key={`question-mobile-${index}`}
                        marginBottom={[8, null, null, 12]}
                      >
                        <ArtistCard
                          image_theme={image_theme}
                          theme={theme}
                          artist={artist}
                        />
                      </Box>
                    ) : null
                  })}
              </Column>
              <Column width={6 / 12}>
                {artists &&
                  artists.length > 0 &&
                  artists.map((artist: any, index: number) => {
                    const everyOther = index % 2 !== 0
                    return everyOther ? (
                      <Box
                        key={`question-desktop-${index}`}
                        marginBottom={[8, null, null, 12]}
                      >
                        <ArtistCard
                          image_theme={image_theme}
                          theme={theme}
                          artist={artist}
                        />
                      </Box>
                    ) : null
                  })}
              </Column>
            </Row>
            <Row className="hideDesktop">
              {artists &&
                artists.length > 0 &&
                artists.map((artist: any, index: number) => (
                  <Column
                    key={`question-${index}`}
                    width={1}
                    marginBottom={[8, null, null, 12]}
                  >
                    <ArtistCard image_theme={image_theme} artist={artist} />
                  </Column>
                ))}
            </Row>
          </Flex>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default ArtistList
