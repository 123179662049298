import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'
import * as styles from './Artists.module.scss'

const Artists = ({
  artists,
  className,
  medium_artists,
  headlines,
  sand,
}: any) => {
  if (!headlines) {
    headlines = artists.slice(0, 1)
    artists = artists.slice(1, artists.length)
  }
  return (artists && artists?.length > 0) ||
    (headlines && headlines.length > 0) ? (
    <Flex
      justifyContent="center"
      alignItems="center"
      height="100%"
      className={classnames(styles.wrapper, className, sand ? styles.sand : '')}
      width={['100%', null, null, '90%']}
    >
      {headlines &&
        headlines.map((artist: any, index: number) => {
          const { name, uuid, content } = artist

          return (
            <Box
              key={uuid}
              marginBottom={[
                2,
                null,
                null,
                index === headlines.length - 1 ? 1 : 3,
              ]}
              className={classnames([styles.artist, styles.headline])}
            >
              {name}
            </Box>
          )
        })}
      <div className={styles.row}>
        {medium_artists &&
          medium_artists.map((artist: any, index: number) => {
            const { name, uuid, content } = artist
            const { label } = content
            return (
              <Box
                key={uuid}
                className={classnames([
                  styles.artist,
                  styles.medium,
                  label ? styles.hasLabel : '',
                ])}
              >
                {name && (
                  <Box as="span" className={styles.name}>
                    {name}
                  </Box>
                )}
                {label && (
                  <Box as="span" className={styles.label}>{`(${label})`}</Box>
                )}
              </Box>
            )
          })}
      </div>
      <div className={styles.row}>
        {artists.map((artist: any, index: number) => {
          const { name, uuid, content } = artist
          const { label } = content
          return (
            <Box
              key={uuid}
              className={classnames([
                styles.artist,
                styles.normal,
                label ? styles.hasLabel : '',
              ])}
            >
              {name && (
                <Box as="span" className={styles.name}>
                  {name}
                </Box>
              )}
              {label && (
                <Box as="span" className={styles.label}>{`(${label})`}</Box>
              )}
            </Box>
          )
        })}
      </div>
    </Flex>
  ) : null
}
export default Artists
