import React from 'react'
import { Box, Flex } from 'reflexbox'
import Question from './Question/Question'
import * as styles from './FAQ.module.scss'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'

import classnames from 'classnames'

import SbEditable from 'storyblok-react'
import Image from 'src/components/UI/Image/Image'
const FAQ = ({ blok }: any) => {
  const { title, description, items, anchor_id, graphics, theme } = blok
  const { bird_3, graphic_1, graphic_2 }: any = graphics ? graphics : {}

  return (
    <SbEditable content={blok}>
      <Container
        id={anchor_id}
        className={classnames(
          styles.wrapper,
          theme && theme === 'sand' ? styles.sand : styles.purple,
          theme && theme === 'sand' ? 'sand-section' : 'purple-section'
        )}
        paddingY={[12, null, null, 23]}
      >
        {bird_3 && (
          <Image
            src={bird_3.filename}
            alt={bird_3.title}
            className={styles.image}
          />
        )}
        {graphic_1 && (
          <Image
            src={graphic_1.filename}
            alt={graphic_1.title}
            className={styles.image}
          />
        )}

        {items.length > 5 && (
          <React.Fragment>
            {graphic_1 && (
              <Image
                src={graphic_1.filename}
                alt={graphic_1.title}
                className={styles.image}
              />
            )}
            {graphic_2 && (
              <Image
                src={graphic_2.filename}
                alt={graphic_2.title}
                className={styles.image}
              />
            )}
          </React.Fragment>
        )}
        {(title || description) && (
          <Box marginBottom={[10, null, null, 14]}>
            {title && (
              <Row justifyContent="center">
                <Column width={[1, null, null, 8 / 12]}>
                  <Box as="h2" className={styles.title}>
                    {title}
                  </Box>
                </Column>
              </Row>
            )}
            {description && (
              <Row justifyContent="center">
                <Column width={[1, null, null, 6 / 12]}>
                  <Box
                    as="p"
                    className={styles.description}
                    marginTop={[10, null, null, 10]}
                    marginBottom={0}
                  >
                    {description}
                  </Box>
                </Column>
              </Row>
            )}
          </Box>
        )}

        <Flex flexDirection="column" width={1} alignItems="center">
          <Flex
            flexDirection="column"
            maxWidth={['100%', null, null, 'var(--container-max-width)']}
          >
            {items &&
              items.length > 0 &&
              items.map((question: any, index: number) => (
                <Question
                  theme={theme ? theme : 'purple'}
                  key={`question-${index}`}
                  question={question}
                  className={styles.question}
                />
              ))}
          </Flex>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default FAQ
