// extracted by mini-css-extract-plugin
export var wrapper = "Tickets-module--wrapper--24U1O";
export var purple = "Tickets-module--purple--2yBjl";
export var sand = "Tickets-module--sand--2V9cB";
export var ticket = "Tickets-module--ticket--3X-ni";
export var title = "Tickets-module--title--5ZGDn";
export var description = "Tickets-module--description--2vXMi";
export var ticketLink = "Tickets-module--ticketLink--23g46";
export var ticketContent = "Tickets-module--ticketContent--3vhvm";
export var ticketTitle = "Tickets-module--ticketTitle--t3lzw";
export var ticketSubtitle = "Tickets-module--ticketSubtitle--2XGbx";
export var bulletList = "Tickets-module--bulletList--Gtep-";
export var bullet = "Tickets-module--bullet--2MI-Y";