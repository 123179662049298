import React from 'react'
import SbEditable from 'storyblok-react'

import { Box, Flex } from 'reflexbox'
import Image from 'src/components/UI/Image/Image'
import Markdown from 'src/components/UI/Markdown/Markdown'
import * as styles from './PageTickets.module.scss'
// import SEO from 'src/components/SEO/SEO'
import classnames from 'classnames'
import Artists from 'src/components/Artists/Artists'
import SEOPage from 'src/components/SEOPage/SEOPage'
import { Container } from 'src/components/UI/Grid/Grid'
import Components from 'src/storyblok/Components'

const PageTickets = ({ blok, full_slug }: any): JSX.Element => {
  const {
    date,
    custom_title,
    artists,
    headlines,
    hero_image,
    image_mobile,
    body,
    seo_data,
    theme,
    graphics,
    title,
  } = blok
  const {
    graphic_1,
    graphic_2,
    graphic_3,
    graphic_4,
    graphic_5,
  }: any = graphics ? graphics : {}

  const seoTitle = `${title} | Rosendal Garden Party`

  let themeClass = ''
  switch (theme) {
    case 'day1':
      themeClass = styles.day1
      break
    case 'day2':
      themeClass = styles.day2
      break
    case 'day3':
      themeClass = styles.day3
      break
    default:
      break
  }

  return (
    <SbEditable content={blok}>
      <SEOPage
        path={full_slug}
        storyblokData={seo_data}
        customData={{
          title: seoTitle || '',
          image: hero_image?.filename || '',
        }}
      />
      <Box className={styles.hero}>
        <Container className={styles.heroInner}>
          <Flex
            paddingY={[4, null, null, 7]}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            className={styles.content}
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <h1>{title}</h1>
            </Flex>
          </Flex>
          <Box className={[styles.background].join(' ')}>
            {/* {graphic_2 && (
              <Image
                src={graphic_2.filename}
                alt={graphic_2.title}
                className={[styles.leftBottomOrange, styles.pattern].join(' ')}
              />
            )} */}

            {graphic_3 && (
              <Image
                src={graphic_3.filename}
                alt={graphic_3.title}
                className={[styles.leftBottomRed, styles.pattern].join(' ')}
              />
            )}
            {/* 
            {graphic_1 && (
              <Image
                src={graphic_1.filename}
                alt={graphic_1.title}
                className={[styles.rightTopPink, styles.pattern].join(' ')}
              />
            )} */}

            {/* {graphic_2 && (
              <Image
                src={graphic_2.filename}
                alt={graphic_2.title}
                className={[styles.rightTopOrange, styles.pattern].join(' ')}
              />
            )} */}

            {graphic_5 && (
              <Image
                src={graphic_5.filename}
                alt={graphic_5.title}
                className={[styles.rightBottom, styles.pattern].join(' ')}
              />
            )}
          </Box>
          <Box className={[styles.imageBackground, themeClass].join(' ')}>
            {hero_image && hero_image.filename ? (
              <Image
                src={hero_image.filename}
                className={classnames(styles.headline)}
              />
            ) : null}
          </Box>
        </Container>
      </Box>

      {body &&
        body?.length > 0 &&
        body.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            image_theme: theme,
          })
        )}
    </SbEditable>
  )
}

export default PageTickets
