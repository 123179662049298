// extracted by mini-css-extract-plugin
export var wrapper = "ArtistCard-module--wrapper--3ipwK";
export var themeSand = "ArtistCard-module--themeSand--2_2yd";
export var themePurple = "ArtistCard-module--themePurple--1pWoV";
export var title = "ArtistCard-module--title--2VYqv";
export var label = "ArtistCard-module--label--3VRDF";
export var imageWrapper = "ArtistCard-module--imageWrapper--DfzVu";
export var day1 = "ArtistCard-module--day1--8Nh5d";
export var day2 = "ArtistCard-module--day2--1B0J3";
export var day3 = "ArtistCard-module--day3--1BAL8";
export var description = "ArtistCard-module--description--3tsrI";