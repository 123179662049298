import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex } from 'reflexbox'
import Button from 'src/components/UI/Button/Button'
import { Container } from 'src/components/UI/Grid/Grid'
import SbEditable from 'storyblok-react'
import * as styles from './GlobalSchedule.module.scss'

const GlobalSchedule = ({ blok, startIndex = 0, theme }: any) => {
  const { schedule } = blok ? blok : { schedule: null }
  const { title, description, days, file_download }: any =
    schedule && schedule.length > 0 ? schedule[0] : {}
  const [active, setActive] = useState(startIndex)
  const activeDay = days && days.length > 0 && days[active]

  return (
    <Box
      className={[
        styles.wrapper,
        theme === 'sand' ? styles.sand : styles.purple,
      ].join(' ')}
    >
      <Container paddingTop={[18]} paddingBottom={[30]}>
        <Flex flexDirection="column" alignItems="center">
          <Box
            textAlign={'center'}
            className={styles.title}
            as="h3"
            marginBottom={[6]}
          >
            {title}
          </Box>
          {description && (
            <Box
              width={[1, null, 4 / 12]}
              textAlign={'center'}
              className={styles.description}
              marginBottom={[8, null, 18]}
              as="p"
            >
              {description}
            </Box>
          )}
          <Flex
            flexDirection="column"
            width={1}
            maxWidth={['100%', null, null, 'var(--container-max-width)']}
            className={styles.schedule}
          >
            <Flex
              className={styles.scheduleHeader}
              width={1}
              justifyContent="center"
            >
              {days &&
                days.map((day: any, index: number) => (
                  <SbEditable key={day._uid} content={day}>
                    <Box
                      onClick={() => {
                        setActive(index)
                      }}
                      width={1}
                      className={
                        index === active
                          ? [styles.button, styles.active].join(' ')
                          : styles.button
                      }
                    >
                      {day.title}
                    </Box>
                  </SbEditable>
                ))}
            </Flex>

            {activeDay && activeDay.rows && (
              <Flex
                width={1}
                flexDirection={'column'}
                key={active}
                className={styles.scheduleBody}
                paddingX={[8]}
              >
                {activeDay.rows.map((artist: any) => (
                  <SbEditable content={artist} key={artist._uid}>
                    <Box
                      className={styles.time}
                      paddingTop={[8]}
                      paddingBottom={[7]}
                    >
                      {artist.time ? `${artist.time} – ` : ''} {artist.name}
                    </Box>
                  </SbEditable>
                ))}
              </Flex>
            )}
          </Flex>
          {file_download && file_download.filename && (
            <Box marginTop={[18]}>
              <Button className={styles.download} href={file_download.filename}>
                <FormattedMessage id="ladda-ner" defaultMessage="Ladda ner" />
              </Button>
            </Box>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default GlobalSchedule
