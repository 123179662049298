import React from 'react'
import Image from 'src/components/UI/Image/Image'

const StoryblokImage = ({
  blok,
  className,
}: {
  blok: any
  className?: string
}) => {
  const { image } = blok

  return image && image.filename ? (
    <Image src={image.filename} alt={image.title} className={className} />
  ) : null
}

export default StoryblokImage
