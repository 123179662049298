import React from 'react'
import { Box, Flex } from 'reflexbox'
import * as styles from './LineUp.module.scss'
import SbEditable from 'storyblok-react'
import Artists from 'src/components/Artists/Artists'
import { FormattedMessage } from 'react-intl'
import Image from 'src/components/UI/Image/Image'
import FlatButton from 'src/components/UI/FlatButton/FlatButton'
import classnames from 'classnames'

const LineUp = ({ blok }: any) => {
  const { events, anchor_id, graphics, theme } = blok
  const {
    graphic_1,
    graphic_2,
    graphic_3,
    graphic_4,
    graphic_6,
    butterfly,
  }: any = graphics ? graphics : {}

  const images = [
    graphic_1,
    graphic_4,
    graphic_2,
    graphic_1,
    graphic_3,
    butterfly,
    graphic_6,
    graphic_1,
    graphic_2,
  ]

  return (
    <SbEditable content={blok}>
      <Box
        id={anchor_id}
        as="section"
        className={classnames(
          styles.wrapper,
          events.length > 1 ? styles.two : '',
          events.length > 2 ? styles.three : '',
          theme && theme === 'sand' ? styles.sand : styles.purple,
          theme && theme === 'sand' ? 'sand-section' : 'purple-section'
        )}
      >
        {images &&
          images.length > 0 &&
          images.map((image: any, index: number) => {
            return image?.filename ? (
              <Image
                key={index}
                src={image.filename}
                alt={image.title}
                className={styles.image}
              />
            ) : null
          })}
        {events &&
          events?.length > 0 &&
          events.map((blok: any) => {
            const { uuid, content, full_slug } = blok

            const { date, artists, headlines, medium_artists } = content || {}

            return (
              <Box
                key={uuid}
                className={styles.event}
                paddingTop={[16, null, null, 23]}
                paddingBottom={[16, null, null, 23]}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Flex className={styles.date} justifyContent="center">
                    <Box as="span" paddingX={[6, null, null, 8]}>
                      {date}
                    </Box>
                  </Flex>

                  <Flex
                    justifyContent="center"
                    className={styles.artists}
                    paddingTop={[20, null, null, 18]}
                    paddingBottom={[17, null, null, 14]}
                  >
                    <Artists
                      sand={theme === 'purple'}
                      headlines={headlines}
                      medium_artists={medium_artists}
                      artists={artists}
                    />
                  </Flex>

                  <Box>
                    <FlatButton
                      purple={theme === 'sand'}
                      big
                      ariaLabel={`Read mo re about ${date}`}
                      href={full_slug}
                    >
                      <FormattedMessage id="läs-mer" defaultMessage="Läs mer" />
                    </FlatButton>
                  </Box>
                </Flex>
              </Box>
            )
          })}
      </Box>
    </SbEditable>
  )
}

export default LineUp
