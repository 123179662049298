import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import Components from 'src/storyblok/Components'
import * as styles from './Iframe.module.scss'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'
import IframeResizer from 'iframe-resizer-react'

const Iframe = ({ blok }: any) => {
  const { title, description, theme, anchor_id } = blok
  const iframeRef = useRef(null)
  const [messageData, setMessageData] = useState()
  const onResized = (data: any) => setMessageData(data)
  const onMessage = (data: any) => {
    setMessageData(data)
    iframeRef.current.sendMessage('Hello back from the parent page')
  }

  return (
    <SbEditable content={blok}>
      <Container
        id={anchor_id}
        className={classnames(
          styles.wrapper,
          theme && theme === 'sand' ? styles.sand : styles.purple,
          theme && theme === 'sand' ? 'sand-section' : 'purple-section'
        )}
        paddingY={[16, null, null, 38]}
      >
        {(title || description) && (
          <Box marginBottom={[10, null, null, 18]}>
            {title && (
              <Row justifyContent="center">
                <Column width={[1, null, null, 8 / 12]}>
                  <Box as="h2" className={styles.title}>
                    {title}
                  </Box>
                </Column>
              </Row>
            )}
            {description && (
              <Row justifyContent="center">
                <Column width={[1, null, null, 6 / 12]}>
                  <Box
                    as="p"
                    className={styles.description}
                    marginTop={[10, null, null, 10]}
                    marginBottom={0}
                  >
                    {description}
                  </Box>
                </Column>
              </Row>
            )}
          </Box>
        )}
        {true && (
          <Row justifyContent="center">
            <Column width={[1, null, null, 6 / 12]}>
              <IframeResizer
                forwardRef={iframeRef}
                heightCalculationMethod="lowestElement"
                inPageLinks
                log
                onMessage={onMessage}
                onResized={onResized}
                src={`https://www.eventim.se/eventseries/3421654/?affiliate=RG7`}
                style={{ width: '1px', minWidth: '100%' }}
              />
            </Column>
          </Row>
        )}
      </Container>
    </SbEditable>
  )
}

export default Iframe
