// extracted by mini-css-extract-plugin
export var info = "PageHome-module--info--3H2m2";
export var fadeIn = "PageHome-module--fadeIn--2j-MH";
export var footer = "PageHome-module--footer--H-wGo";
export var fadeUp = "PageHome-module--fadeUp--2mmE-";
export var pattern = "PageHome-module--pattern--2ASaD";
export var hero = "PageHome-module--hero--1Cuub";
export var heroInner = "PageHome-module--heroInner--2TaWl";
export var leftBottom = "PageHome-module--leftBottom--hongc";
export var leftTop = "PageHome-module--leftTop--2NfwL";
export var topLeft = "PageHome-module--topLeft--2u7qR";
export var topCenter = "PageHome-module--topCenter--1dgma";
export var topRight = "PageHome-module--topRight--ojsvD";
export var rightTop = "PageHome-module--rightTop--3gmlq";
export var rightBottom = "PageHome-module--rightBottom---_PW-";
export var bottomRight = "PageHome-module--bottomRight--1hD12";
export var bottomLeft = "PageHome-module--bottomLeft--TxYjO";
export var mobileTopLeft = "PageHome-module--mobileTopLeft--_I1CI";
export var fjaril = "PageHome-module--fjaril--XDD82";
export var bird = "PageHome-module--bird--3GeUH";
export var title = "PageHome-module--title--2BCuR";
export var second = "PageHome-module--second--2QkxZ";
export var third = "PageHome-module--third--39DxW";
export var firstTitle = "PageHome-module--firstTitle--26tbo";
export var mobileInfo = "PageHome-module--mobileInfo--3EXX4";
export var partners = "PageHome-module--partners--3nxR6";
export var big = "PageHome-module--big--3IeKW";
export var small = "PageHome-module--small--ofJlc";
export var fadeRight = "PageHome-module--fadeRight--ZHfqP";
export var scaleIn = "PageHome-module--scaleIn--20TFo";