// extracted by mini-css-extract-plugin
export var wrapper = "Artists-module--wrapper--2-c2M";
export var sand = "Artists-module--sand--1Mt3i";
export var row = "Artists-module--row--15FVE";
export var artist = "Artists-module--artist--2aDYH";
export var headline = "Artists-module--headline--33Sg0";
export var normal = "Artists-module--normal--1am2f";
export var hasLabel = "Artists-module--hasLabel---OHxe";
export var label = "Artists-module--label--2GxFX";
export var star = "Artists-module--star--16_4Z";
export var medium = "Artists-module--medium--1c4I8";
export var country = "Artists-module--country--1mBc3";