import React from 'react'
import { Box, Flex } from 'reflexbox'
import * as styles from './ArtistCard.module.scss'
import Markdown from 'src/components/UI/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'

const ArtistCard = ({ artist, theme, image_theme }: any) => {
  const { name, content } = artist
  const { country, image, description, label } = content

  let imageClass = ''
  // switch (image_theme) {
  //   case 'day1':
  //     imageClass = styles.day1
  //     break
  //   case 'day2':
  //     imageClass = styles.day2
  //     break
  //   case 'day3':
  //     imageClass = styles.day3
  //     break
  //   default:
  //     break
  // }

  return (
    <Box
      width={1}
      flexDirection="column"
      className={[
        styles.wrapper,
        theme === 'purple' ? styles.themePurple : styles.themeSand,
      ].join(' ')}
      padding={[6, null, null, 8]}
    >
      <Box as="h3" className={styles.title}>
        {name}
        {label && (
          <Box
            as="span"
            marginLeft={[1]}
            marginRight={[2]}
            className={styles.label}
          >{`(${label})`}</Box>
        )}
        {country ? ` (${country})` : ''}
      </Box>

      {image?.filename && (
        <Box
          className={[styles.imageWrapper, imageClass].join(' ')}
          marginTop={[4, null, null, 7]}
        >
          <Image src={image.filename} alt={name} />
        </Box>
      )}

      {description && (
        <Box className={styles.description} marginTop={[6, null, null, 8]}>
          <Markdown>{description}</Markdown>
        </Box>
      )}
    </Box>
  )
}

export default ArtistCard
