import React from 'react'
import { Box, Flex } from 'reflexbox'
import Components from 'src/storyblok/Components'
import * as styles from './Information.module.scss'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

const Information = ({ blok }: any) => {
  const { title, description, content, anchor_id, theme } = blok
  return (
    <SbEditable content={blok}>
      <Container
        id={anchor_id}
        className={classnames(
          styles.wrapper,
          theme && theme === 'sand' ? styles.sand : styles.purple,
          theme && theme === 'sand' ? 'sand-section' : 'purple-section'
        )}
        paddingY={[16, null, null, 38]}
      >
        {(title || description) && (
          <Box marginBottom={[10, null, null, 18]}>
            {title && (
              <Row justifyContent="center">
                <Column width={[1, null, null, 8 / 12]}>
                  <Box as="h2" className={styles.title}>
                    {title}
                  </Box>
                </Column>
              </Row>
            )}
            {description && (
              <Row justifyContent="center">
                <Column width={[1, null, null, 6 / 12]}>
                  <Box
                    as="p"
                    className={styles.description}
                    marginTop={[10, null, null, 10]}
                    marginBottom={0}
                  >
                    {description}
                  </Box>
                </Column>
              </Row>
            )}
          </Box>
        )}

        <Flex flexDirection="column" width={1} alignItems="center">
          <Flex
            flexDirection="column"
            width={1}
            maxWidth={['100%', null, null, 'var(--container-max-width)']}
          >
            {content &&
              content?.length > 0 &&
              content.map((blok: any) =>
                React.createElement(Components(blok.component), {
                  key: blok._uid,
                  blok: blok,
                })
              )}
          </Flex>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default Information
