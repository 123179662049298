import React, { useRef, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import Image from 'src/components/UI/Image/Image'
import * as styles from './StoryblokVideo.module.scss'
import classnames from 'classnames'
import { useVideoPlayer } from 'src/helpers/hooks'

const StoryblokVideo = (props: any) => {
  const { blok, className, onClick } = props
  const { poster_image, video } = blok
  const { filename = '' } = video || {}
  const [videoStarted, setVideoStarted] = useState(false)
  const videoElement = useRef(null)
  const { togglePlay } = useVideoPlayer(videoElement)
  const extension = filename
    ? filename.substr(filename.lastIndexOf('.') + 1)
    : null

  const onPlay = () => {
    togglePlay()
    if (!videoStarted) {
      setVideoStarted(true)
    }
  }

  if (filename && extension && extension === 'mp4') {
    return (
      <Box
        className={classnames(styles.videoWrapper, className)}
        onClick={() => {
          onPlay()
          if (onClick) {
            onClick()
          }
        }}
      >
        {/* {!videoStarted && (
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            textAlign={'center'}
            className={classnames(styles.playButton, 'hideDesktop')}
          >
            {'Play'}
          </Flex>
        )} */}
        <Flex
          alignItems={'center'}
          justifyContent="center"
          className={styles.poster}
        >
          {poster_image?.filename && (
            <Image
              className={classnames(videoStarted && styles.hidden)}
              src={poster_image.filename}
              alt={poster_image.title}
            />
          )}
          {!videoStarted && <Box as="span">PLAY</Box>}
        </Flex>
        <video
          ref={videoElement}
          loop
          playsInline
          controls
          //    poster={poster_image?.filename ? poster_image.filename : null}
        >
          <source src={filename} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
    )
  } else {
    return (
      <Flex
        className={styles.placeholder}
        width={1}
        justifyContent="center"
        alignItems="center"
      >
        Upload mp4 video format
      </Flex>
    )
  }
}

export default StoryblokVideo
