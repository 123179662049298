import React from 'react'
import { Flex, Box } from 'reflexbox'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './CTA.module.scss'
import { Props } from './types'
import classnames from 'classnames'
import { useRecoilState } from 'recoil'
import { activeMenuState } from 'src/recoil/atoms'

const Content = ({
  title,
  color = 'var(--purple)',
  type = '',
}: Props): JSX.Element => {
  return (
    <Box
      className={classnames(styles.shape, type === 'small' ? styles.small : '')}
    >
      <svg viewBox="0 0 260 261" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M123.088 260.202c-2.48-14.653-9.017-30.433-19.613-47.341-10.595-17.133-25.7-33.026-45.312-47.68C38.775 150.528 19.386 141.172 0 137.114v-14.202c19.162-4.509 37.535-12.85 55.12-25.024C72.928 85.49 87.806 70.611 99.754 53.252 111.93 35.442 119.706 17.86 123.088.5h14.202c2.029 11.272 6.087 22.882 12.174 34.83 6.087 11.723 13.864 22.995 23.333 33.816 9.693 10.595 20.514 20.176 32.462 28.742 17.81 12.625 35.957 20.966 54.443 25.024v14.202c-12.399 2.48-25.249 7.553-38.549 15.217-13.076 7.665-25.249 16.795-36.521 27.391-11.272 10.37-20.515 21.303-27.729 32.801-10.595 16.907-17.133 32.801-19.613 47.679h-14.202z"
          fill={color}
        />
      </svg>
      <Box className={styles.ctaText} as="span">
        {title}
      </Box>
    </Box>
  )
}

const CTA = (props: Props): JSX.Element => {
  const { to, newsletterCta } = props
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)
  return to && !newsletterCta ? (
    <LinkComponent className={styles.wrapper} to={to}>
      <Content {...props} />
    </LinkComponent>
  ) : (
    <Box
      onClick={
        newsletterCta
          ? () => {
              setActiveMenu('newsletter')
            }
          : () => {}
      }
      className={[styles.wrapper, newsletterCta ? styles.newsletter : ''].join(
        ' '
      )}
    >
      <Content {...props} />
    </Box>
  )
}

export default CTA
