import React from 'react'
import SbEditable from 'storyblok-react'

import { Box, Flex } from 'reflexbox'
import * as styles from './PageHome.module.scss'
import Image from 'src/components/UI/Image/Image'
import Markdown from 'src/components/UI/Markdown/Markdown'

import CTA from 'src/components/CTA/CTA'

import Components from 'src/storyblok/Components'
import SEOPage from 'src/components/SEOPage/SEOPage'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

const PageHome = ({ blok, full_slug }: any): JSX.Element => {
  const {
    first_column_info,
    body,
    seo_data,
    graphics,
    big_partners,
    partners,
  } = blok

  const {
    graphic_1,
    graphic_2,
    graphic_3,
    graphic_7,
    graphic_8,
    bird_1,
    bird_3,
    butterfly,
  }: any = graphics ? graphics : {}

  return (
    <SbEditable content={blok}>
      <SEOPage path={full_slug} storyblokData={seo_data} />

      <Box className={styles.hero}>
        <Flex
          paddingX={[4, null, null, 6]}
          paddingTop={['22px', null, null, 10]}
          paddingBottom={[4, null, null, 8]}
          flexDirection="column"
          className={styles.heroInner}
          alignItems="center"
          justifyContent={'center'}
        >
          {graphic_7 && (
            <Image
              src={graphic_7.filename}
              alt={graphic_7.title}
              className={[styles.leftBottom, styles.pattern].join(' ')}
            />
          )}
          {bird_3 && (
            <Image
              src={bird_3.filename}
              alt={bird_3.title}
              className={[styles.leftTop, styles.pattern].join(' ')}
            />
          )}
          {graphic_8 && (
            <Image
              src={graphic_8.filename}
              alt={graphic_8.title}
              className={[styles.topLeft, styles.pattern].join(' ')}
            />
          )}
          {graphic_3 && (
            <Image
              src={graphic_3.filename}
              alt={graphic_3.title}
              className={[styles.topCenter, styles.pattern].join(' ')}
            />
          )}
          {graphic_2 && (
            <Image
              src={graphic_2.filename}
              alt={graphic_2.title}
              className={[styles.topRight, styles.pattern].join(' ')}
            />
          )}
          {graphic_1 && (
            <Image
              src={graphic_1.filename}
              alt={graphic_1.title}
              className={[styles.rightTop, styles.pattern].join(' ')}
            />
          )}
          {graphic_3 && (
            <Image
              src={graphic_3.filename}
              alt={graphic_3.title}
              className={[styles.rightBottom, styles.pattern].join(' ')}
            />
          )}
          {/* {graphic_2 && (
            <Image
              src={graphic_2.filename}
              alt={graphic_2.title}
              className={[styles.bottomRight, styles.pattern].join(' ')}
            />
          )} */}
          {/* {graphic_5 && (
            <Image
              src={graphic_5.filename}
              alt={graphic_5.title}
              className={[styles.bottomLeft, styles.pattern].join(' ')}
            />
          )} */}

          {first_column_info && (
            <Flex
              paddingX={[4, null, null, 8]}
              paddingY={[5, null, null, 8]}
              className={styles.info}
              flexDirection="column"
            >
              <Markdown>{first_column_info}</Markdown>
            </Flex>
          )}
          {partners && partners.length > 0 && (
            <Flex
              paddingX={[4, null, null, 8]}
              paddingY={[3, null, null, 8]}
              className={styles.partners}
            >
              <div className={styles.big}>
                {big_partners &&
                  big_partners.map((partner: any) =>
                    partner && partner.link && partner.link.cached_url ? (
                      <LinkComponent
                        to={partner.link ? partner.link.cached_url : ''}
                        key={partner.uid}
                      >
                        {partner.logotype && (
                          <Image
                            src={partner.logotype.filename}
                            alt={partner.logotype.title}
                          />
                        )}
                      </LinkComponent>
                    ) : (
                      <div key={partner.uid}>
                        {partner.logotype && (
                          <Image
                            src={partner.logotype.filename}
                            alt={partner.logotype.title}
                          />
                        )}
                      </div>
                    )
                  )}
              </div>
              <div className={styles.small}>
                {partners &&
                  partners.map((partner: any) =>
                    partner && partner.link && partner.link.cached_url ? (
                      <LinkComponent
                        to={partner.link ? partner.link.cached_url : ''}
                        key={partner.uid}
                      >
                        {partner.logotype && (
                          <Image
                            src={partner.logotype.filename}
                            alt={partner.logotype.title}
                          />
                        )}
                      </LinkComponent>
                    ) : (
                      <div key={partner.uid}>
                        {partner.logotype && (
                          <Image
                            src={partner.logotype.filename}
                            alt={partner.logotype.title}
                          />
                        )}
                      </div>
                    )
                  )}
              </div>
            </Flex>
          )}
          <Flex
            width={1}
            className={styles.title}
            flexDirection="column"
            as="h1"
            paddingBottom={[6, null, null, 0]}
          >
            <Box className={styles.firstTitle} as="span">
              {'Rosendal'}
            </Box>
            <Flex justifyContent={'center'}>
              <Box marginRight={'0.1em'} className={styles.second} as="span">
                {'Garden'}
              </Box>
              <Box marginLeft={'0.1em'} className={styles.third} as="span">
                {bird_1 && (
                  <Image
                    src={bird_1.filename}
                    alt={bird_1.title}
                    className={styles.bird}
                  />
                )}
                {butterfly && (
                  <Image
                    src={butterfly.filename}
                    alt={butterfly.title}
                    className={styles.fjaril}
                  />
                )}
                {'Party'}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Box>

      {body &&
        body?.length > 0 &&
        body.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}
    </SbEditable>
  )
}

export default PageHome
