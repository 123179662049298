import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import Image from 'src/components/UI/Image/Image'
import YouTube from 'react-youtube'
import * as styles from './YoutubeVideo.module.scss'
import { PlayIcon } from 'src/components/UI/Icon/Icon'
import classnames from 'classnames'
import isNode from 'is-node'

const YoutubeVideo = (props: any) => {
  const { blok } = props
  const { poster_image, youtube_id } = blok
  const [started, setStarted] = useState(false)
  const [youtubeRef, setYoutubeRef] = useState<any>(null)
  const wrapperRef = useRef(null)
  const [load, setLoaded] = useState(false)

  const observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting && !load) {
        setLoaded(true)
      }
    }
  }

  const observer = !isNode
    ? new IntersectionObserver(observeHandler, {
        rootMargin: '0px 0px 0px 0px',
      })
    : null

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      observer?.observe(wrapperRef.current)
    }
    return () => {
      observer?.disconnect()
    }
  }, [])

  const youtubeOptions: any = {
    height: '100%',
    width: '100%',
    playerVars: {
      controls: 1,
      disablekb: 1,
      loop: 0,
      modestbranding: 1,
    },
  }

  const onYoutubeReady = (e: any) => {
    if (e?.target) {
      setYoutubeRef(e.target)
    }
  }
  const playVideo = () => {
    setStarted(true)
    if (youtubeRef?.playVideo) {
      youtubeRef.playVideo()
    }
  }

  const overlayHidden = started || !poster_image?.filename

  return (
    <Box ref={wrapperRef} width={1} className={styles.videoWrapper}>
      <Box
        className={
          overlayHidden
            ? [styles.contentWrapper, styles.playing].join(' ')
            : styles.contentWrapper
        }
      >
        {!overlayHidden ? (
          <Image
            src={poster_image.filename}
            alt={poster_image.title}
            className={styles.poster}
          />
        ) : null}

        {youtube_id && (
          <Box
            className={classnames(
              styles.youtube,
              !overlayHidden ? styles.hidden : ''
            )}
          >
            {load && (
              <YouTube
                onReady={onYoutubeReady}
                opts={youtubeOptions}
                videoId={youtube_id}
              />
            )}
          </Box>
        )}
      </Box>
      <Box
        className={classnames(
          styles.overlay,
          overlayHidden ? styles.hidden : ''
        )}
      />
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        textAlign={'center'}
        onClick={() => playVideo()}
        className={classnames(styles.play, overlayHidden ? styles.hidden : '')}
        height="100%"
        width={1}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          className={styles.icon}
        >
          <PlayIcon />
        </Flex>
      </Flex>
    </Box>
  )
}

export default YoutubeVideo
