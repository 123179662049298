import React from 'react'
import { Box } from 'reflexbox'
import GlobalSchedule from 'src/storyblok/GlobalComponents/GlobalSchedule/GlobalSchedule'
import SbEditable from 'storyblok-react'

const Schedule = ({ blok }: any) => {
  const { graphics, schedule, start_index, anchor_id, theme } = blok
  return (
    <SbEditable content={blok}>
      <Box
        id={anchor_id}
        className={
          theme && theme === 'sand' ? 'sand-section' : 'purple-section'
        }
      >
        <GlobalSchedule
          theme={theme}
          blok={schedule}
          graphics={graphics}
          startIndex={start_index ? parseInt(start_index) : 0}
        />
      </Box>
    </SbEditable>
  )
}

export default Schedule
