import React from 'react'
import SbEditable from 'storyblok-react'

import { Flex } from 'reflexbox'
import * as styles from './PageArtist.module.scss'

import { Container } from 'src/components/UI/Grid/Grid'
import ArtistCard from 'src/storyblok/Modules/ArtistList/ArtistCard/ArtistCard'

const PageArtist = (props: any): JSX.Element => {
  const { blok, name } = props
  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper} paddingY={[3, null, null, 12]}>
        <Flex justifyContent="center" alignItems="center" minHeight="100vh">
          <ArtistCard artist={{ name: name, content: blok }} />
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default PageArtist
