import React from 'react'
import { Box, Flex } from 'reflexbox'
import * as styles from './Offers.module.scss'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import SbEditable from 'storyblok-react'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import classnames from 'classnames'

const Offers = ({ blok }: any) => {
  const { title, description, offers, bullet_list, anchor_id } = blok

  const cards = ['Orginal', 'Premium', 'VIP']

  return (
    <SbEditable content={blok}>
      <Box id={anchor_id} className="anchor"></Box>
      <Container
        className={classnames(styles.wrapper, 'purple-section')}
        paddingY={[16, null, null, 38]}
      >
        {(title || description) && (
          <Box marginBottom={[10, null, null, 18]}>
            {title && (
              <Row justifyContent="center">
                <Column width={[1, null, null, 8 / 12]}>
                  <Box as="h2" className={styles.title}>
                    Erbjudanden
                  </Box>
                </Column>
              </Row>
            )}

            {description && (
              <Row justifyContent="center">
                <Column width={[1, null, null, 6 / 12]}>
                  <Box
                    as="p"
                    className={styles.description}
                    marginTop={[5, null, null, 10]}
                    marginBottom={0}
                  >
                    {description}
                  </Box>
                </Column>
              </Row>
            )}
          </Box>
        )}

        <Flex flexDirection="column" width={1} alignItems="center">
          <Box
            maxWidth={['100%', null, null, 'var(--container-max-width)']}
            width={1}
          >
            <Row flexDirection="row" justifyContent="center">
              {offers.map((offer) => {
                const {
                  _uid,
                  title,
                  description,
                  subtitle,
                  button,
                  information,
                  bullet_list,
                } = offer
                return (
                  <Column width={[1, null, null, 4 / 12]} key={_uid}>
                    <Box
                      width={1}
                      flexDirection="column"
                      className={styles.card}
                      padding={[6, null, null, 8]}
                    >
                      {title && (
                        <Box as="h3" className={styles.cardTitle}>
                          {title}
                        </Box>
                      )}

                      {description && (
                        <Box
                          className={styles.description}
                          marginTop={[6, null, null, 6]}
                        >
                          {description}
                        </Box>
                      )}
                      {subtitle && (
                        <Box
                          as="h4"
                          marginTop={[6, null, null, 6]}
                          className={styles.cardSubTitle}
                        >
                          {subtitle}
                        </Box>
                      )}
                      {information && (
                        <Box
                          marginTop={[6, null, null, 6]}
                          className={styles.information}
                        >
                          {information}
                        </Box>
                      )}
                      {bullet_list && bullet_list?.length > 0 && (
                        <Box
                          as="ul"
                          className={styles.bulletList}
                          marginTop={information ? [3] : [6, null, null, 6]}
                        >
                          {bullet_list.map((bullet: any) => (
                            <Box
                              as="li"
                              key={bullet?._uid}
                              marginBottom={[2]}
                              className={styles.bullet}
                            >
                              {bullet?.title && <Box>{bullet.title}</Box>}
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Column>
                )
              })}
            </Row>
          </Box>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default Offers
