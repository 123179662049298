import React from 'react'
import { Box, Flex } from 'reflexbox'
import * as styles from './Question.module.scss'
import Markdown from 'src/components/UI/Markdown/Markdown'
import classnames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

const Question = ({ question, className, theme }: any) => {
  const [active, setActive] = React.useState(false)
  const variants = {
    open: { height: 'auto', opacity: 1 },
    closed: { height: 0, opacity: 0 },
  }
  const transition = {
    ease: 'easeInOut',
  }
  return (
    <Box
      className={[
        className,
        styles.wrapper,
        active ? styles.active : '',
        theme === 'purple' ? styles.themePurple : styles.themeSand,
      ].join(' ')}
    >
      <Flex
        onClick={() => {
          setActive(!active)
        }}
        alignItems="center"
        as="button"
        className={classnames(styles.question, 'use-editor')}
      >
        {question?.question && question.question}
      </Flex>
      <Box className={styles.answer} width={[1, null, null, 2 / 3]}>
        <motion.div
          animate={active ? 'open' : 'closed'}
          variants={variants}
          transition={transition}
        >
          {question?.answer && <Markdown>{question.answer}</Markdown>}
        </motion.div>
      </Box>
    </Box>
  )
}

export default Question
