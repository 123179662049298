// extracted by mini-css-extract-plugin
export var top = "PageEnglish-module--top--1HbmI";
export var fadeIn = "PageEnglish-module--fadeIn--o3XPN";
export var footer = "PageEnglish-module--footer--1DJTX";
export var fadeUp = "PageEnglish-module--fadeUp--12JBO";
export var pattern = "PageEnglish-module--pattern--20cMO";
export var cta = "PageEnglish-module--cta--2NzNz";
export var scaleIn = "PageEnglish-module--scaleIn--3rsdV";
export var wrapper = "PageEnglish-module--wrapper--2l-lK";
export var title = "PageEnglish-module--title--4bmQH";
export var third = "PageEnglish-module--third--HuOz1";
export var second = "PageEnglish-module--second--Pe7ol";
export var info = "PageEnglish-module--info--2NU7k";
export var mobileInfo = "PageEnglish-module--mobileInfo--1MKBd";
export var sponsors = "PageEnglish-module--sponsors--3mIw5";
export var fadeRight = "PageEnglish-module--fadeRight--39uPB";