import React from 'react'
import SbEditable from 'storyblok-react'

import { Box, Flex } from 'reflexbox'
import * as styles from './PageContent.module.scss'
import Image from 'src/components/UI/Image/Image'
import { useIntl } from 'react-intl'
// import SEO from 'src/components/SEO/SEO'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import Components from 'src/storyblok/Components'
import Markdown from 'src/components/UI/Markdown/Markdown'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import classnames from 'classnames'
import SEOPage from 'src/components/SEOPage/SEOPage'

const PageContent = (props: any): JSX.Element => {
  const { blok, name, full_slug } = props
  const { navItems, body, seo_data, title } = blok

  return (
    <SbEditable content={blok}>
      <SEOPage
        path={full_slug}
        storyblokData={seo_data}
        customData={{
          title: title || name || '',
        }}
      />

      <Container className={styles.wrapper}>
        <Box className={styles.headerPlaceholder} />
        <Row className={styles.page} height="100%">
          <Column
            className={styles.sidebarWrapper}
            width={[1, null, null, 4 / 12]}
            paddingY={[6, null, null, 8]}
            height={['auto', null, null, 'calc(100vh - var(--header-height))']}
          >
            <Flex
              className={styles.sidebar}
              justifyContent="flex-end"
              flexDirection="column"
              height={['auto', null, null, '100%']}
            >
              {navItems &&
                navItems?.length > 0 &&
                navItems.map((navItem: any, index: number) => (
                  <Box
                    key={navItem?.slug}
                    marginBottom={
                      index === navItems.length - 1 ? 0 : [2, null, null, 5]
                    }
                    paddingLeft={[6, null, null, 0]}
                    className={classnames(
                      styles.link,
                      navItem?.name === title && styles.active
                    )}
                  >
                    <LinkComponent to={navItem?.slug}>
                      {navItem?.name}
                    </LinkComponent>
                  </Box>
                ))}
            </Flex>
          </Column>
          <Column
            width={[1, null, null, 8 / 12]}
            paddingTop={[8]}
            paddingBottom={[15, null, null, 25]}
          >
            {body &&
              body?.length > 0 &&
              body.map((blok: any, index: number) => {
                return (
                  <Box
                    key={blok._uid}
                    marginBottom={
                      index !== body.length - 1 ? [10, null, null, 20] : 0
                    }
                  >
                    {React.createElement(Components(blok.component), {
                      key: blok._uid,
                      blok: blok,
                    })}
                  </Box>
                )
              })}
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default PageContent
