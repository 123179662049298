// extracted by mini-css-extract-plugin
export var wrapper = "PageContent-module--wrapper--30uBk";
export var headerPlaceholder = "PageContent-module--headerPlaceholder--wlyqX";
export var page = "PageContent-module--page--2Rd7B";
export var sidebarWrapper = "PageContent-module--sidebarWrapper--3yfqX";
export var sidebar = "PageContent-module--sidebar--399RN";
export var link = "PageContent-module--link--S5Ko4";
export var active = "PageContent-module--active--3CzqB";
export var rotateInTopLeft = "PageContent-module--rotateInTopLeft--WDKM0";
export var rotateInTopRight = "PageContent-module--rotateInTopRight--38r0H";
export var rotateInBottomRight = "PageContent-module--rotateInBottomRight--a5HRK";
export var fadeIn = "PageContent-module--fadeIn--1i6zS";
export var fadeUp = "PageContent-module--fadeUp--r-l4q";
export var fadeRight = "PageContent-module--fadeRight--TOGvX";
export var scaleIn = "PageContent-module--scaleIn--7AF1D";